import auth from '@/middleware/auth.js'

export default [{
    path: '',
    component: () =>
        import ('@/layouts/Constelacion'),
    children: [{
        path: '/inversionistas',
        name: 'inversionistas',
        component: () =>
            import ('@/apps/inversionistas/Dashboard'),
        meta: {
            title: 'Inversionistas',
            middleware: auth
        }
    }, 
    ]
}]